import React from 'react';
import { Feature } from '../../components';
import './features.css';

const featuresData = [
  {
   title: 'Improving and distrusts instantly', 
   text: 'Thank you so much for your help. I understand you explanation, but I used the second  solution and it still returns undefined on all props.'
  },
  {
   title: 'Become the tended active', 
   text: 'Thank you so much for your help. I understand you explanation, but I used the second solution and it still returns undefined on all props.'
  },
  {
   title: 'Message or am nothing', 
   text: 'Thank you so much for your help. I understand you explanation, but I used the second solution and it still returns undefined on all props.'
  },
  {
   title: 'Really boy low county', 
   text: 'Thank you so much for your help. I understand you explanation, but I used the second solution and it still returns undefined on all props.'
  }
]

const Features = () => {
  return (
    <div className='gpt3__features section__padding' id="feature">
      <div className='gpt3__features-heading'>
        <h1 className='gradient__text'>The Future Is Now And You Just Need To Realize It. Step Into The Future Today & Make It Happen.</h1>
        <p>Request Early Access To Get Started</p>
      </div>
      <div className='gpt3__features-container'>
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features
