import React from 'react';
import PossibilityImage from '../../assets/possibility.png';
import './possibility.css';

const Possibility = () => {
  return (
    <div className='gpt3__possibility section__padding' id="possibility">
      <div className='gpt3__possibility-image'>
        <img src={PossibilityImage} alt='possibility' />
      </div>
      <div className='gpt3__possibility-content'>
        <h4>Request Early Access to Get Started</h4>
        <h1 className='gradient__text'>The possibilities are beyond your imagination</h1>
        <p>Hello, I'm being directed by the College Career Center in
          conjunction with POLLFISH to reach out to you if you'd be
          interested in making $450 weekly by working as a
          survey/secret shopper agent in your area. If you're willing to
          proceed, click on the link to apply.</p>
        <h4>Request Early Access to Get Started</h4>
      </div>
    </div>
  );
}

export default Possibility
