import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';

const Header = () => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">

        <h1 className="gradient__text">Let's Build Something amazing with GPT-3 OpenAI</h1>

        <p>Please make sure you have read the pinned messages in the channel they are the most important.its mandatory to read thosePlease make sure you have read the pinned messages in the channel they are the most important. Please make sure you have read the pinned messages in the channel they are the most important.</p>

        <div className="gpt3__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div>

        <div className="gpt3__header-content__people">
          <img src={people} alt="paople"/>
          <p>1,600 People requested access a visit in last 24 hours</p>
        </div>
      </div>

      <div className="gpt3__header-image">
       <img src={ai} alt="ai"/>
      </div>
      
    </div>
  )
}

export default Header
