import React from 'react';
import Feature from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className='gpt3__whatgpt3 section__margin' id="wgpt3">
      <div className='gpt3__whatgpt3-feature'>
        <Feature title="What is GPT-3" text="The Product component is probably rendering that p tag with the 150 and the class. you don't see the react components when inspecting the generated html, you see the html returned by the functionThe Product component is probably rendering and the class. you don't see the react ng the generated html, you see the html " />
      </div>
      <div className='gpt3__whatgpt3-heading'>

        <h1 className='gradient__text'>The possibilities are beyond your imagination</h1>

        <p>Explore The Library</p>

      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title="Chatbots" text="Each component gets it's properties via the 1st argument, usually named props. Thus you should deconstruct the props.Each component gets it's properties via the 1st argument, usually named props. Thus you should deconstruct the props." />
        <Feature title="Knowledgebase" text="Each component gets it's properties via the 1st argument, usually named props. Thus you should deconstruct the props.Each component gets it's properties via the 1st argument, usually named props. Thus you should deconstruct the props." />
        <Feature title="Education" text="Each component gets it's properties via the 1st argument, usually named props. Thus you should deconstruct the props.Each component gets it's properties via the 1st argument, usually named props. Thus you should deconstruct the props." />
      </div>
    </div>
  )
}

export default WhatGPT3
